import { FC } from 'react';
import Button, {IButton} from '@/components/Button';
import RichTextRenderer from '@/components/RichText';

export interface IHero {
    content: {
        content: {
            json: {};
        };
        image: {
            title: string;
            description: string;
            url: string;
        }
        ctaButton?: IButton;
        variant: 'Full width image' | 'Half width image';
    };
    image?: any,
    title?: any;
    className?: string;
}

const HeroBanner: FC<IHero> = function ({ content, className = '' }) {
    return (
        <>
            {content.variant === 'Full width image' &&
            <section className={`flex w-full md:aspect-[2/1] p-0 min-h-[30rem] max-h-[42rem] relative before:content-['*'] before:absolute before:inset-0 before:bg-gradient-to-b before:from-transparent before:to-primary bg-cover ${className}`} 
                style={{ backgroundImage: `url(${content.image.url})` }}>
                <div className="container relative self-end">
                    <div className="flex flex-col py-8 z-10">
                        <div className="">
                            <RichTextRenderer content={content.content} className="text-white text-center" />
                            {content.ctaButton && <div className="mt-4 text-center z-10">
                                <Button label={content.ctaButton.label} url={content.ctaButton.url || content.ctaButton.page?.menuTitle} className="button-primary"/>
                            </div>}
                        </div>
                    </div>
                </div>
            </section>}
            {content.variant === 'Half width image' &&
            <section className={`  p-0 ${className}`}>
                <div className="container">
                    <div className="sm:flex">
                        <div className="sm:w-1/2">
                            <img src={content.image.url} alt={content.image.description || content.image.title } className="h-full w-full aspect-video object-cover" />
                        </div>
                        <div className="bg-secondary sm:w-1/2 flex flex-col justify-center py-8 sm:py-20 px-14">
                            <RichTextRenderer content={content.content} className="text-center" />
                            {content.ctaButton && <div className="mt-4 text-center z-10">
                                <Button label={content.ctaButton.label} url={content.ctaButton.url || content.ctaButton.page?.menuTitle} />
                            </div>}
                        </div>
                    </div>
                </div>
            </section>}
        </>
    );
};

export default HeroBanner;
